import React from "react";

const Donatepage = () => {
    return (
        <div className="title">
            <h4>You are encouraged to donate in person but if you can't make it, please feel free to donate here.</h4>
        </div>
    );
}

export default Donatepage;