import axios from 'axios'
// import { getCookie } from './csrf';


// const csrftoken = getCookie('csrftoken')

const baseURL = 'http://kbamn.org:8000/api/'

const AxiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        // "X-CSRFToken": csrftoken,
        "Content-Type": "application/json",
        accept: "application/json"
    },
    withCredentials: true
});

export default AxiosInstance;