import React from "react";

const Scavengerpage = () => {
    return (
        <div className="title">
            <h4>There will be prizes for the winners</h4>
        </div>
    );
}

export default Scavengerpage;