

const Carinfo = `
    If your check engine light comes on, it can give you a feeling of dread
    Is something about to go wrong? How much is it going to cost?
    Don't panic — let's look at why the light might be on and what to do about it.
    Take the quiz or keep reading below to get an idea of what might be `;

export default Carinfo;


